import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//happ-icons 
import { IconSettings } from "happ-components/happ-icons/icons";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { useHistory } from 'react-router-dom';
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import useValidatorforms from "happ-custom-hooks/useValidatorforms";

import { login_session } from 'happ-redux/happ-actions/login/loginActions';
const useStyles = makeStyles(styles);


const initState = {
  email: '',
  password: ''
};

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    const {
      state,
      errors,
      handlerSubmit,
      handlerChange
    } = useValidatorforms(initState, loginUser);
    const {email, password} = state;
    const errorLogin = useSelector(state => state.login.error_msg);
    function loginUser(){
      const sendLogin = {
        email,
        password
      }
      dispatch (login_session(sendLogin));
    }
    
    useEffect(() => {
        let id = setTimeout(function () {
        setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
        window.clearTimeout(id);
        };
    });
    
    const classes = useStyles();
    return ( 
        <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form
              onSubmit={handlerSubmit}
            >
              <Card login>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Inicio de sesión</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    onChange={handlerChange}
                    value={email}
                    name="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p>{errorLogin.email && errorLogin.email}</p>
                  <p>{errors.email && errors.email}</p>
                  <CustomInput
                    labelText="Password"
                    id="password"
                    name="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={password}
                    onChange={handlerChange}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="icon-login-field">
                          <IconSettings />
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                  <p>{errorLogin.password && errorLogin.password}</p>
                  <p>{errors.password && errors.password}</p>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button 
                    color="rose" 
                    simple 
                    type="submit"
                    size="lg" 
                    block
                  >
                    Continuar
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
}
 
export default LoginPage;