import {
    INIT_SESSION,
    LOGIN,
    LOGIN_ERROR,
    LOGOUT,
    ME
} from 'happ-redux/happ-types'
import { verificateLogin, getProfile, loginTrue } from 'happ-services/login/login-services';
export function login_session(login_data) {
    return async (dispatch) => {
        dispatch(init_session);
        try {
            const responseLogin = await verificateLogin(login_data);
            if (responseLogin.status === 400) {
                dispatch(login_error(responseLogin.data.error))
            } else {
                responseLogin.token = responseLogin.data.existingAdmin._id;
                dispatch(login(responseLogin));
                window.location.href = '/';
            }
        } catch (error) {
            dispatch(login_error(true))
        }
    }
}

export function logout_session() {
    return (dispatch) => {
        dispatch(logout());
    }
}

export function mySession() {
    return (dispatch) => {
        try {
            const token = localStorage.getItem('token');
            const responseProfile = getProfile(token);
            if ( responseProfile !== null ) {
                dispatch(me(responseProfile));
            } else {
                dispatch(logout())
            }
        } catch (error) {
            console.log('er',error);
        }
    }
}

export function IsLoginTrue() {
    return (dispatch) => {
        try {
            const token = localStorage.getItem('token');
            const response = loginTrue(token);
            if ( !response ){
                localStorage.removeItem('token');
            } 
        } catch (error) {
            console.log('err', error);
        }
    }
}

export function admin_name(login_data) {
    return async (dispatch) => {
        try {
            const responseLogin = await verificateLogin(login_data);
            if (responseLogin.status === 400) {
                dispatch(login_error(responseLogin.data.error))
            } else {
                responseLogin.token = responseLogin.data.existingAdmin._id;
                dispatch(login(responseLogin));
                window.location.href = '/';
            }
        } catch (error) {
            dispatch(login_error(true))
        }
    }
}

const init_session = () => ({
    type: INIT_SESSION,
});

const login = login_data => ({
    type: LOGIN,
    payload: login_data
})

const login_error = state_error => ({
    type: LOGIN_ERROR,
    payload: state_error
})

const logout = () => ({
    type: LOGOUT,
})

const me = profile => ({
    type: ME,
    payload: profile
})