import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import RoutesMap from "happ-routes/RoutesMap";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import "happ-components/happ-icons/icons.css";
import "assets/css/happ-global.css";

/*
  Estructuras para redux:
    1. Importar el store creado.
    2. Importar el Provider de react-redux
    3. Si tenemos Rutas dentro de esta se declara el Provider pasando como atributo el store.
*/
import store from './happ-store';
import { Provider } from 'react-redux';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <RoutesMap />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
