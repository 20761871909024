import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import '../Operative/Operative.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { checkRut, formatRut } from "react-rut-formatter";
//happ-icons and happ-spinners
import { IconIM } from "happ-components/happ-icons/icons";
//components
import InputValidateDni from 'happ-components/Operatives/InputValidateDni';
//actions
import { sendToVerificateIdentityAction, certificationDniImedAction } from 'happ-redux/happ-actions/operative/operativeActions';

const ProfileStyle = makeStyles(styles);  

const ValidateIdentity = () => {
    const [state, setState] = useState({
        dni: ''
    });
    const [errorDni, setErrorDni] = useState(false);
    const location = useLocation();
    const {dni} = state;

    useEffect(() => {
        if ( location.pathname === '/validar-identidad' && location.search !== '' ) {
            const { search } = location;
            const subString = search.split('id_certificacion=', 2)[1];
            const separateItems = subString.split('&');
            const idCertification = separateItems[0];
            const status = separateItems[1].split('=')[1];
            const msgError = separateItems[3].split('=')[1];
            const certificateBody = {
                idCertification,
                status,
                msgError
            }
            dispatch(certificationDniImedAction(certificateBody));
        }
    }, [location])
    
    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setState({
            ...state,
            dni: formatted
        });
    }
    const sendToVerificateIdentity = e => {
        e.preventDefault();
        if (dni === '' || (!checkRut(dni))) {
            setErrorDni(true);
            return;
        }
        setErrorDni(false);
        dispatch(sendToVerificateIdentityAction(dni));
    }
    const closeCurrentWindow = e => {
        window.opener = self;
        window.close();
    } 
    const openImed = () => {
        const url = urlVerificateIdentity ? urlVerificateIdentity : '#'
        window.open(url, 'newwindow', 'width=700,height=700'); 
        return false;
    }
    const ProfileClass = ProfileStyle();
    const dispatch = useDispatch();
    const {urlVerificateIdentity, errorEnrollment, certificateOk, certificateDni, certificateErrorMsg} = useSelector(state => state.operative);
    return ( 
        <GridContainer className="global-container">
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon className={`${ProfileClass.iconBack} content-icons`}>
                            <IconIM />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <InputValidateDni 
                                    classes={ProfileClass}
                                    dni={dni}
                                    errorDni={errorDni}
                                    handlerChangeDni={handlerChangeDni}
                                    sendToVerificateIdentity={sendToVerificateIdentity}
                                />
                            </GridItem>
                            {urlVerificateIdentity ? (
                                <GridItem xs={12} sm={12} md={12} className="btn-validation-grid">
                                    <button 
                                        className="btn primary-bg-color btn-agenda" 
                                        type='button'
                                        onClick={openImed}
                                    >
                                        Validar Identidad
                                    </button>
                                </GridItem>
                            ) : null}
                            {errorEnrollment !== null ? (
                                <GridItem xs={12} sm={12} md={12} className="btn-validation-grid">
                                    {errorEnrollment}
                                </GridItem>
                            ) : null}
                            {certificateOk !== null ? (
                                <>
                                    <GridItem xs={12} sm={12} md={12} className="btn-validation-grid">
                                        {certificateOk}
                                    </GridItem>
                                    <Button
                                        className={`happ-background-color color-white btn-search-dni`}
                                        type="button"
                                        onClick={closeCurrentWindow}
                                    >
                                        Cerrar Ventana 
                                    </Button>
                                </>
                            ) : null}
                            {certificateErrorMsg !== null ? (
                                <>
                                    <GridItem xs={12} sm={12} md={12} className="btn-validation-grid">
                                        {certificateErrorMsg}
                                    </GridItem>
                                    <Button
                                        className={`happ-background-color color-white btn-search-dni`}
                                        type="button"
                                        onClick={closeCurrentWindow}
                                    >
                                        Cerrar Ventana
                                    </Button>
                                </>
                            ) : null}

                        </GridContainer>    
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>                
    );
}
 
export default ValidateIdentity;