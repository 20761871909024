import React, {useEffect, useState, createRef} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Switch, Redirect} from "react-router-dom";
import { SiteRoutes, MenuOptions } from "happ-routes";
import { IsLoginTrue } from 'happ-redux/happ-actions/login/loginActions';


// @material-ui/core components
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import stylesAuth from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import stylesSite from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";


//components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

//views
import LoginPage from "happ-views/Login/LoginPage.js";
import Poll from 'happ-views/Poll/Poll.js';
import Profile from "happ-views/Profile/Profile.js"
import Sampling from "happ-views/Sampling/Sampling";
import ValidateIdentity from "happ-views/ValidateIdentity/ValidateIdentity";



const useStylesAuth = makeStyles(stylesAuth);
const useStylesSite = makeStyles(stylesSite);
let ps;

const RoutesMap = () => {
    const dispatch = useDispatch();
    dispatch(IsLoginTrue());
    const token = localStorage.getItem('token');


    const wrapper = createRef();
    const classes = useStylesAuth();
    const classesSite = useStylesSite();

    // states and functions
    const [mobileOpen, setMobileOpen] = useState(false);
    const [miniActive, setMiniActive] = useState(false);
    const [image, setImage] = useState(
        require("assets/img/sidebar-2.jpg").default
    );
    const [color, setColor] = useState("blue");
    const [bgColor, setBgColor] = useState("black");
    // const [hasImage, setHasImage] = useState(true);
    const [logo, setLogo] = useState(
        require("assets/img/logo-white.svg").default
    );
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    const onlyAdminRoutes = (routes) => {
        return routes.filter(route => route.layout !== '/auth');
    }
    const isFullScreen = () => {
        return window.location.pathname !== "/full-screen-maps";
    };

    const RoutesWithoutSidebar = ({ component: Component, ...rest }) => {
        return (
            <>
                <AuthNavbar brandText="" {...rest} />
                <div className={classes.wrapper} ref={wrapper}>
                    <div
                    className={classes.fullPage}
                    style={{ background: "#002db7"}}
                    >
                    <Route {...rest} render={props => ( <Component {...props} /> )} />
                    <Footer white />
                    </div>
                </div>
            </>
        );
    }
    const RoutesWithSidebar = ({ component: Component, isAuth, ...rest }) => {
        
        const mainPanelClasses =
            classesSite.mainPanel +
            " " +
            cx({
            [classesSite.mainPanelSidebarMini]: miniActive,
            [classesSite.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1,
        });
        // ref for main panel div
        const mainPanel = createRef();
        useEffect(() => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
                });
                document.body.style.overflow = "hidden";
            }
            window.addEventListener("resize", resizeFunction);

            // Specify how to clean up after this effect:
            return function cleanup() {
                if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                }
                window.removeEventListener("resize", resizeFunction);
            };
        });
        return (

            <div className={classesSite.wrapper}>
                <Sidebar
                    routes={onlyAdminRoutes(MenuOptions)}
                    logoText={"Screening Happ"}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={handleDrawerToggle}
                    open={mobileOpen}
                    color={color}
                    bgColor={bgColor}
                    miniActive={miniActive}
                    {...rest}
                />
                <div className={mainPanelClasses} ref={mainPanel}>
                <AdminNavbar
                    sidebarMinimize={sidebarMinimize.bind(this)}
                    miniActive={miniActive}
                    brandText=""
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                />
                {isFullScreen() ? (
                    <div className={classesSite.content}>
                        <div className={classesSite.container}>
                            <Route 
                                {...rest} 
                                render={props => token ? (
                                    ( <Component {...props} /> )
                                ) : (
                                    <Redirect to="/login" />
                            )} />
                        </div>
                    </div>
                    ) : (
                    <div className={classesSite.map}>
                        <Route 
                            {...rest} 
                            render={props => token ? (
                                ( <Component {...props} /> )
                            ) : (
                                <Redirect to="/login" />
                        )} />
                    </div>
                )}
                {isFullScreen() ? <Footer fluid /> : null}
                </div>
            </div>
        );
    }


    return ( 
        <Switch>
            <RoutesWithoutSidebar exact path={SiteRoutes.Login.path} component={LoginPage} />
            <RoutesWithSidebar exact path={SiteRoutes.Poll.path} component={Poll} />
            <RoutesWithSidebar exact path={SiteRoutes.Profile.path} component={Profile} />
            <RoutesWithSidebar exact path={SiteRoutes.Sampling.path} component={Sampling} />
            <RoutesWithSidebar exact path={SiteRoutes.ValidateIdentity.path} component={ValidateIdentity} />
        </Switch>
     );
}
 
export default RoutesMap;