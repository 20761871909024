import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);

const PollList = ({inputValue, ...rest}) => {
    const [checked, setChecked] = useState([]);
    console.log(inputValue);
    const {
        errors,
        handlerChange,
        nametaskInput,
        tasksIndexes,
        tasks
    } = rest;
    const classes = useStyles();
    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    setChecked(newChecked);
    };

    return ( 
        <>
            <Checkbox
                tabIndex={-1}
                checked={inputValue}
                name='encuesta_rcv'
                checkedIcon={<Check className={classes.checkedIcon} />}
                onClick={() => handleToggle(inputValue)}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                checked: classes.checked,
                root: classes.root,
                }}
            />hola
            <Checkbox
                tabIndex={-1}
                checked={inputValue}
                onClick={() => handleToggle(inputValue)}
                name='encuesta_rcv'
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                checked: classes.checked,
                root: classes.root,
                }}
            />hola
        </>
        
    );
}
 
export default PollList;