import React from 'react';
import {useSelector} from 'react-redux';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const SelectOperative = ({classes, operatives, ...rest}) => {
    const {
        state,
        handlerChangeOperative,
    } = rest;
    const { operative } = state;
    return (
        
        <div className="select-operativo-container"> 
            {operatives ? (
                <FormControl
                    fullWidth
                    className={`${classes.selectFormControl} select-custom-operative`}
                >
                    <InputLabel
                        htmlFor="operative"
                        className={classes.selectLabel}
                    >
                        Seleccionar Operativo
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: `${classes.selectMenu} my-select`,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        value={operative ? operative : ''}
                        onChange={handlerChangeOperative}
                        inputProps={{
                            name: "operative",
                            id: "operative",
                        }}
                    >
                        <MenuItem
                            disabled
                            classes={{
                            root: classes.selectMenuItem,
                            }}
                            value=""
                        >
                        Seleccionar Operativo
                        </MenuItem>
                        {operatives ? operatives.map(operative => (
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            key={operative._id}
                            value={operative}
                        >
                            {`${operative.company.name} - ${operative.branchOffice.name}`}
                        </MenuItem>
                        )): null}
                    </Select>
                </FormControl>
            ) : (
                <p className="p_error">No tienes operativos asignados</p>
            )}
            
      </div>
     );
}
 
export default SelectOperative;