import {
    SET_INIT_VIEW,
    INIT_OPERATIVE,
    OPERATIVE,
    OPERATIVE_ERROR,
    CURRENT_OPERATIVE,
    INIT_GET_PATIENT_BY_DNI,
    GET_PATIENT_BY_DNI,
    GET_PATIENT_BY_DNI_ERROR,
    INIT_GET_PATIENT_RESULTS_BY_DNI,
    GET_PATIENT_RESULTS_BY_DNI,
    GET_PATIENT_RESULTS_BY_DNI_ERROR,
    INIT_SAVE_PATIENT_RESULTS_BY_DNI,
    SAVE_PATIENT_RESULTS_BY_DNI,
    SAVE_PATIENT_RESULTS_BY_DNI_ERROR,
    SET_CREATE_STATUS,
    INIT_SAVE_PATIENT,
    SAVE_PATIENT,
    SAVE_PATIENT_ERROR,
    INIT_GET_LAST_POLL_RESULTS,
    GET_LAST_POLL_RESULTS,
    GET_LAST_POLL_RESULTS_ERROR,
    INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI,
    SAVE_PATIENT_POLL_RESULTS_BY_DNI,
    SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR,
    INIT_VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED_ERROR,
    INIT_CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED_ERROR
}  from 'happ-redux/happ-types';
import { 
    getOperativesByDate, 
    getInputnames, 
    getOnlyPoll, 
    findPatientByDni, 
    getLastResultTest, 
    prepareStructureResults,
    sendHealthBenefitsResults,
    sendnewPatient,
    getLastResultsPoll,
    prepareStructurePollResults,
    sendSurveyPatient,
    verificateDniImed,
    certificateDniImed 
} from 'happ-services/operative/operative-services';
import moment from 'moment';

export function set_init_state_view(view){
    return async (dispatch) => {
        dispatch(set_state_view(view));
        try {
            const token = localStorage.getItem('token');
            const today = moment().format('YYYY-MM-DD');
            const response = await getOperativesByDate(token, today); 
            if ( response.status == 200 ) {
                dispatch(operative(response.data.responseOperativesToday));
            } else {
                dispatch(operative_error(response.data.msg));
            }
        } catch (error) {
            dispatch(operative_error(error));
        }
    }
}

export function getInitOperatives() {
    return async (dispatch) => {
        dispatch(init_operative);
        try {
            const token = localStorage.getItem('token');
            const today = moment().format('YYYY-MM-DD');
            const response = await getOperativesByDate(token, today); 
            if ( response.status == 200 ) {
                dispatch(operative(response.data.responseOperativesToday));
            } else {
                dispatch(operative_error(response.data.msg));
            }
        } catch (error) {
            dispatch(operative_error(error));
        }
    }
}

export function getCurrentOperative(state, operative, view){
    return (dispatch) => {
        try {
            const response = {};
            const formOperativeValues = getInputnames(state, operative.healthBenefits);
            const onlyPoll = getOnlyPoll(operative.healthBenefits);
            response.onlyPoll = onlyPoll;
            response.operative = operative;
            response.view = view;
            response.formOperativeValues = formOperativeValues;
            dispatch(current_operative(response));
        } catch (error) {
            dispatch(operative_error(error));
        }
    }
}

export function getPatient(dni) {
    return async (dispatch) => {
        dispatch(init_get_patient_by_dni());
        try {
            const response = await findPatientByDni(dni);
            if ( response.status === 200 ){
                dispatch(get_patient_by_dni(response.data.existingPatient));
            } else {
                dispatch(get_patient_by_dni_error(response.data.msg));
            }
        } catch (error) {
            dispatch(get_patient_by_dni_error(error));
        }
    }
}

export function savePatient(patient){
    console.log(patient)
    return async (dispatch) => {
        dispatch(init_save_patient());
        try {
            const response = await sendnewPatient(patient);
            if ( response.status === 200 ) {
                dispatch(save_patient(response.status));
                dispatch(set_create_status());
            } else {
                dispatch(save_patient_error(response.data.msg))
            }
        } catch (error) {
            dispatch(save_patient_error(error.data.msg))
        }
    }
}

export function getLastResultPatient(dni, operative, operativeDate){
    return async (dispatch) => {
        dispatch(init_get_patient_results_by_dni);
        try {
            const response = await getLastResultTest(dni, operative, operativeDate);
            if (response.status === 200) {
                dispatch(get_patient_results_by_dni(response.data));
            } else {
                dispatch(get_patient_results_by_dni_error(response.data));
            }
        } catch (error) {
            dispatch(get_patient_results_by_dni_error(error.data.msg));
        }
    }
}

export function getLastPollResultPatient(dni, pack){
    return async (dispatch) => {
        dispatch(init_get_last_poll_results());
        try {
            const response = await getLastResultsPoll(dni, pack);
            if ( response.status === 200 ) {
                response.data.ok = 'Ultimos resultados disponibles';
                dispatch(get_last_poll_results(response.data));
            } else {
                dispatch(get_last_poll_results_error(response.data));
            }
        } catch (error) {
            dispatch(get_last_poll_results_error(error.data));
        }
    }
}

// Es la funcion que se ejecuta al guardar en TOMA DE MUESTRAS y guarda los resultados
export function saveHealthBenefitResults(patient, state){
    return async (dispatch) => {
        dispatch(init_save_patient_results_by_dni());
        try {
            const structureCreate = prepareStructureResults(patient, state);
            const response = await sendHealthBenefitsResults(structureCreate);
            if (response.status === 200) {
                dispatch(save_patient_results_by_dni(response.status));
                dispatch(set_create_status())
            } else {
                dispatch(save_patient_results_by_dni_error(response.data.msg))
            }
        } catch (error) {
            dispatch(save_patient_results_by_dni_error(error.data.msg))
        }
    }
}

export function savePollResults(patient, state, onlyPoll){
    return async (dispatch) => {
        dispatch(init_save_patient_poll_results_by_dni());
        try {
            const structureCreate = prepareStructurePollResults(patient, state, onlyPoll[0]);
            const response = await sendSurveyPatient(structureCreate);
            if (response.status === 200) {
                dispatch(save_patient_poll_results_by_dni(response.data));
                dispatch(set_create_status());
            } else {
                dispatch(save_patient_poll_results_by_dni_error(response.data));
            }
        } catch (error) {
            dispatch(save_patient_poll_results_by_dni_error(error.data.msg));
        }
    }
}

export function sendToVerificateIdentityAction(dni){
    return async (dispatch) => {
        dispatch(init_verificate_dni_imet());
        try {
            const response = await verificateDniImed(dni);
            if (response.status === 200) {
                dispatch(verificate_dni_imet(response.data));
            } else {
                dispatch(verificate_dni_imet_error(response.data.msg));
            }
        } catch (error) {
            dispatch(verificate_dni_imet_error(error.data.msg));
        }
    }
}

export function certificationDniImedAction(body) {
    return async (dispatch) => {
        dispatch(init_certification_dni_imed());
        try {
            const response = await certificateDniImed(body);
            if ( response.status === 200 ) {
                dispatch(certificate_dni_imed(response.data));
            } else {
                dispatch(certificate_dni_imed_error(response.data.msg));
            }
        } catch (error) {
            dispatch(certificate_dni_imed_error(error.data.msg));
        }
    }
}

export function setCreateStatus(){
    return (dispatch) => {
        dispatch(set_create_status())
    }
}


const set_state_view = view  => ({
    type: SET_INIT_VIEW,
    payload: view
})

const init_operative = () => ({
    type: INIT_OPERATIVE,
});

const operative = operativesArray => ({
    type: OPERATIVE,
    payload: operativesArray
});

const operative_error = error => ({
    type: OPERATIVE_ERROR,
    payload: error
});

const current_operative = response => ({
    type: CURRENT_OPERATIVE,
    payload: response
});

const init_get_patient_by_dni = () => ({
    type: INIT_GET_PATIENT_BY_DNI
});

const get_patient_by_dni = patient => ({
    type: GET_PATIENT_BY_DNI,
    payload: patient
});

const get_patient_by_dni_error = error => ({
    type: GET_PATIENT_BY_DNI_ERROR,
    payload: error
});

const init_get_patient_results_by_dni = () => ({
    type: INIT_GET_PATIENT_RESULTS_BY_DNI
});

const get_patient_results_by_dni = results => ({
    type: GET_PATIENT_RESULTS_BY_DNI,
    payload: results
});

const get_patient_results_by_dni_error = error => ({
    type: GET_PATIENT_RESULTS_BY_DNI_ERROR,
    payload: error
});

const init_save_patient_results_by_dni = () => ({
    type: INIT_SAVE_PATIENT_RESULTS_BY_DNI
});

const save_patient_results_by_dni = response => ({
    type: SAVE_PATIENT_RESULTS_BY_DNI,
    payload: response
});

const save_patient_results_by_dni_error = error => ({
    type: SAVE_PATIENT_RESULTS_BY_DNI_ERROR,
    payload: error
});

const set_create_status = () => ({
    type: SET_CREATE_STATUS
})

const init_save_patient = () => ({
    type: INIT_SAVE_PATIENT
});

const save_patient = response => ({
    type: SAVE_PATIENT,
    payload: response
});

const save_patient_error = error => ({
    type: SAVE_PATIENT_ERROR,
    payload: error
});

const init_get_last_poll_results = () => ({
    type: INIT_GET_LAST_POLL_RESULTS
});

const get_last_poll_results = polls => ({
    type: GET_LAST_POLL_RESULTS,
    payload: polls
});

const get_last_poll_results_error = error => ({
    type: GET_LAST_POLL_RESULTS_ERROR,
    payload: error
});

const init_save_patient_poll_results_by_dni = () => ({
    type: INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI
});

const save_patient_poll_results_by_dni = response => ({
    type: SAVE_PATIENT_POLL_RESULTS_BY_DNI,
    payload: response
});

const save_patient_poll_results_by_dni_error = error => ({
    type: SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR,
    payload: error
});

const init_verificate_dni_imet = () => ({
    type: INIT_VERIFICATE_DNI_IMED
});

const verificate_dni_imet = response => ({
    type: VERIFICATE_DNI_IMED,
    payload: response
});

const verificate_dni_imet_error = error => ({
    type: VERIFICATE_DNI_IMED_ERROR,
    payload: error
});

const init_certification_dni_imed = () => ({
    type: INIT_CERTIFICATION_DNI_IMED
});

const certificate_dni_imed = response => ({
    type: CERTIFICATION_DNI_IMED,
    payload: response
});

const certificate_dni_imed_error = error => ({
    type: CERTIFICATION_DNI_IMED_ERROR,
    payload: error
});