import React from 'react';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

const InputValidateDni = ({classes, dni, handlerChangeDni, sendToVerificateIdentity, errorDni}) => {
    return (
        <div className="content-inputs-patients">
            <div className="content-dni">
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} className="input-dni-validate">
                <CustomInput
                    labelText="Rut Ej: 11111111-1"
                    id="dni"
                    name="dni"
                    placeholder=""
                    value={dni ? dni : ''}
                    onChange={handlerChangeDni}
                />
                {errorDni ? (
                    <p className="p_require">El rut no es valido</p>
                ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <Button
                    className={`${classes.backButton} happ-background-color color-white btn-search-dni`}
                    type="button"
                    onClick={sendToVerificateIdentity}
                >
                    Continuar
                </Button>
                </GridItem>
            </GridContainer>    
            </div>
        </div>      
    );
}
 
export default InputValidateDni;