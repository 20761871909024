import React, {useState,useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesDash from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import '../Operative/Operative.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert2 from 'react-sweetalert2';



//happ-components
import SelectOperative from 'happ-components/Operatives/SelectOperative'; 
import InputsPatient from 'happ-components/Operatives/InputsPatient';
import InputDni from 'happ-components/Operatives/InputDni';


//happ-icons 
import { IconIM } from "happ-components/happ-icons/icons";

import {getInitOperatives, savePatient} from 'happ-redux/happ-actions/operative/operativeActions';
import useValidatorforms from "happ-custom-hooks/useValidatorforms";


const stylesPanel = {
    cardTitle,
    pageSubcategoriesTitle: {
      color: "#3C4858",
      textDecoration: "none",
      textAlign: "center",
    },
    cardCategory: {
      margin: "0",
      color: "#999999",
    },
  };

const ProfileStyle = makeStyles(styles);  
const useStyles = makeStyles(stylesPanel);
const dashStyles = makeStyles(stylesDash);

let profileInitState = {
    operative: '',
    dni: '',
    firstName: '',
    lastName: '',
    lastName2: '',
    email: '',
    phone: '',
    gender: '',
    healthForecast: '',
    birthDay: '',
    street: '',
    commune: '',
    region: '',
    company: '',
    branchOffice: '',
  };

const Profile = () => {
    const classes = useStyles();
    const ProfileClass = ProfileStyle();
    const dispatch = useDispatch();
    const [swalProps, setSwalProps] = useState({
        show: true,
        text: 'Colaborador creado ó actualizado con exito',
        icon: 'success',
    });
    useEffect(() => {
        dispatch(getInitOperatives());
    }, []);
    const operativesArray = useSelector(state => state.operative.operatives);
    const currentOperativeProfile = useSelector(state => state.operative.currentOperativeProfile);
    const create_status = useSelector(state => state.operative.create_status);
    const disabled = useSelector(state => state.operative.disabled);
    const okInputs = useSelector(state => state.operative.okInputs);
    const {
        state,
        errors,
        comunes,
        stateReg,
        handlerSubmit,
        handlerChange,
        handlerDateChange,
        handlerChangeDni,
        handlerChangeReg,
        handlerChangeOperativeProfile
    } = useValidatorforms(profileInitState, sendProfile);
    function sendProfile(){
        console.log(state);
        const fullLastName = state.lastName2 !== '' ? state.lastName + ' ' + state.lastName2 : state.lastName;
        state.lastName = fullLastName;
        dispatch(savePatient(state));
        state.dni = '';
        state.firstName = '';
        state.lastName = '';
        state.lastName2 = '';
        state.email = '';
        state.phone = '';
        state.gender = '';
        state.healthForecast = '';
        state.birthDay = '';
        state.street = '';
        state.commune = '';
        state.region = '';
        state.company = '';
        state.branchOffice = '';
    }  
    return ( 
        <GridContainer className="global-container">
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon className={`${ProfileClass.iconBack} content-icons`}>
                            <IconIM />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        {create_status ? (
                            <SweetAlert2 {...swalProps} 
                            />
                        ) : null}
                        <form
                            onSubmit={handlerSubmit}
                            className='form-operative'
                        >
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <SelectOperative 
                                        classes={ProfileClass}
                                        operatives={operativesArray}
                                        handlerChangeOperative={handlerChangeOperativeProfile}
                                        state={state}
                                    />
                                </GridItem>
                                {currentOperativeProfile ? (
                                    <>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <InputDni 
                                                classes={ProfileClass}
                                                handlerChange={handlerChange}
                                                handlerChangeDni={handlerChangeDni}
                                                state={state}
                                                errors={errors}
                                            />
                                            <InputsPatient 
                                                hiddenSection={`show-section-${okInputs}`}
                                                classes={ProfileClass}
                                                comunes={comunes}
                                                stateReg={stateReg}
                                                handlerChange={handlerChange}
                                                state={state}
                                                handlerDateChange={handlerDateChange}
                                                handlerChangeReg={handlerChangeReg}
                                                errors={errors}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} className={`btn-center show-section-${okInputs}`}>
                                            <Button
                                                className={`${classes.backButton} happ-background-color color-white`}
                                                type="submit"
                                                disabled={disabled}
                                            >
                                                Guardar
                                            </Button>
                                        </GridItem>                                            
                                    </>
                                ) : null}
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>                    
    );
}
 
export default Profile;