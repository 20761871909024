import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Switch from "@material-ui/core/Switch";
import CustomTabs from "happ-components/Operatives/CustomTabs";
import Tasks from "happ-components/Operatives/Tasks";
import BugReport from "@material-ui/icons/BugReport";
import {poll} from 'happ-preloaded-data/data';
import PollList from 'happ-components/Operatives/PollList';
import { grayColor } from 'assets/jss/material-dashboard-pro-react';
const HealthBenefitsInputs = ({healthBenefit, classes, ...rest}) => {
    const [checkedA, setCheckedA] = useState(true);
    const { code, nroInputs } = healthBenefit;
    const {handlerChange, state, errors, handlerclickUpdatePoll} = rest;
    const {
        ayunas,
        glicemia,
        peso,
        altura,
        deportista,
        colesterol,
        trigliceridos,
        sistolica_bi,
        diastolica_bi,
        encuesta_rcv,
        encuesta_pos,
        imc,
        circunferencia,
        comentarios
    } = state;
    return ( 
        (() => {
            if ( code !== 'undefined' ) {
                switch (code) {
                  case 'IMC001':
                    return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={2}>
                                <CustomInput
                                    labelText='Peso'
                                    id='peso'
                                    onChange={handlerChange}
                                    name='peso'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={peso ? peso : ''}
                                    inputProps={{
                                        type:'number'
                                    }}
                                />
                                <p className="p_require">{errors.peso && errors.peso}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <CustomInput
                                    labelText='Altura cm'
                                    id='altura'
                                    onChange={handlerChange}
                                    name='altura'
                                    pattern="[0-9]*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type:'number'
                                    }}
                                    value={altura ? altura : ''}
                                />
                                <p className="p_require">{errors.altura && errors.altura}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                        checked={deportista}
                                        name='deportista'
                                        value={deportista ? deportista : ''}
                                        onChange={handlerChange}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label="¿Es deportista?"
                                />
                            </GridItem>                           
                        </GridContainer>
                    ) 
                  case 'PL001':
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText='Colesterol total'
                                    id='colesterol'
                                    onChange={handlerChange}
                                    name='colesterol'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={colesterol ? colesterol : ''}
                                />
                                <p className="p_require">{errors.colesterol && errors.colesterol}</p>
                            </GridItem>       
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText='Trigliceridos'
                                    id='trigliceridos'
                                    onChange={handlerChange}
                                    name='trigliceridos'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={trigliceridos ? trigliceridos : ''}
                                />
                                <p className="p_require">{errors.trigliceridos && errors.trigliceridos}</p>
                            </GridItem>                            
                        </GridContainer>
                      )
                  case 'PA001':
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={2}>
                                <CustomInput
                                    labelText='Sistolica'
                                    id='sistolica_bi'
                                    onChange={handlerChange}
                                    name='sistolica_bi'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={sistolica_bi ? sistolica_bi : ''}
                                />
                                <p className="p_require">{errors.sistolica_bi && errors.sistolica_bi}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <CustomInput
                                    labelText='Diastolica'
                                    id='diastolica_bi'
                                    onChange={handlerChange}
                                    name='diastolica_bi'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={diastolica_bi ? diastolica_bi : ''}
                                />
                                <p className="p_require">{errors.diastolica_bi && errors.diastolica_bi}</p>
                            </GridItem>
                        </GridContainer>
                      )
                  case 'GL001':
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                        checked={ayunas}
                                        name='ayunas'
                                        value={ayunas ? ayunas : ''}
                                        onChange={handlerChange}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label="En ayunas?"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                                <CustomInput
                                    labelText="Glicemia"
                                    id="glicemia"
                                    onChange={handlerChange}
                                    value={glicemia ? glicemia : ''}
                                    name="glicemia"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                <p className="p_require">{errors.glicemia && errors.glicemia}</p>
                            </GridItem>
                        </GridContainer>
                      )
                  case 'CIA001':
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={5}>
                                <CustomInput
                                    labelText="Circunferencia abdominal (INPUT)"
                                    id="circunferencia"
                                    onChange={handlerChange}
                                    value={circunferencia ? circunferencia : ''}
                                    name="circunferencia"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                <p className="p_require">{errors.circunferencia && errors.circunferencia}</p>
                            </GridItem>
                        </GridContainer>
                      )
                    case 'TXT001':
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={5}>
                                <TextareaAutosize
                                    // labelText="Comentarios del paciente (Max. 130 caracteres)"
                                    id="comentarios"
                                    onChange={handlerChange}
                                    value={comentarios ? comentarios : ''}
                                    name="comentarios"
                                    // formControlProps={{
                                    //     fullWidth: true,
                                    // }}
                                    placeholder = "Comentarios del paciente (Max. 130 caracteres)"
                                    maxLength="130"
                                    rows={7}
                                    // maxRows={3}
                                    className='text-area-observation'
                                />
                                <p className="p_require">{errors.comentarios && errors.comentarios}</p>
                            </GridItem>
                        </GridContainer>
                      )
                  case 'RCV001':
                      const questionsKeys = Array.from(Array(nroInputs.length).keys());
                      const questions = nroInputs.map(question => question.text);
                      return (
                        <GridContainer className="content-inputs-health-benefits">
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    tabs={[
                                    {
                                        tabName: '',
                                        tabContent: (
                                        <Tasks
                                            checkedIndexes={encuesta_pos ? encuesta_pos : []}
                                            handlerclickUpdatePoll={handlerclickUpdatePoll}
                                            inputValue={encuesta_rcv ? encuesta_rcv : ''}
                                            nametaskInput='encuesta_rcv'
                                            tasksIndexes={questionsKeys}
                                            tasks={questions}
                                        />
                                        ),
                                    }]}
                                />
                            </GridItem>
                        </GridContainer>
                      )                 
                  default:
                    return null
                }
            } else {
                return null;
            }
        })()
    );
}
 
export default HealthBenefitsInputs;