import React from 'react';
import {useSelector} from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";

const InputsPatient = ({classes, ...rest}) => {
    const { 
      comunes,
      stateReg,
      handlerChange,
      state,
      handlerDateChange,
      handlerChangeReg,
      errors,
      hiddenSection 
    } = rest;

    const {
      firstName,
      lastName,
      lastName2,
      email,
      phone,
      gender,
      healthForecast,
      birthDay,
      street,
      commune,
      region,
      company,
      branchOffice
    } = state;
    let inputProps = {
      placeholder: 'Fecha de nacimiento',
      name: 'birthDay',
      value: birthDay ? birthDay : ''
    }
    console.log(branchOffice);
    return ( 
        <div className={`content-inputs-patients ${hiddenSection}`}>
            <div className="content-inputs-profile">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Nombres"
                          id="firstName"
                          name="firstName"
                          onChange={handlerChange}
                          value={firstName ? firstName : ''}
                          formControlProps={{
                              fullWidth: true,
                          }}
                        />
                    <p className="p_require">
                      {errors.firstName && errors.firstName}
                    </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                        labelText="Apellido Paterno"
                        id="lastName"
                        name="lastName"
                        value={lastName ? lastName : ''}
                        onChange={handlerChange}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        />
                    <p className="p_require">
                      {errors.lastName && errors.lastName}
                    </p>    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                        labelText="Apellido Materno"
                        id="lastName2"
                        name="lastName2"
                        value={lastName2 ? lastName2 : ''}
                        onChange={handlerChange}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        />
                    <p className="p_require">
                      {errors.lastName2 && errors.lastName2}
                    </p>    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                        labelText="Correo Electronico"
                        id="email"
                        name="email"
                        value={email ? email : ''}
                        onChange={handlerChange}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        />
                        <p className="p_require">
                          {errors.email && errors.email}
                        </p>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                        labelText="Teléfono"
                        id="phone"
                        name="phone"
                        value={phone ? phone : ''}
                        onChange={handlerChange}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        />
                    <p className="p_require">
                      {errors.phone && errors.phone}
                    </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                            <InputLabel
                            htmlFor="gender"
                            className={classes.selectLabel}
                            >
                            Genero
                            </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={gender ? gender : ''}
                                    onChange={handlerChange}
                                    inputProps={{
                                        name: "gender",
                                        id: "gender",
                                    }}
                                >
                                    <MenuItem
                                    disabled
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                    value="Sin genero"
                                    >
                                    Genero
                                    </MenuItem>
                                    <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="F"
                                    >
                                    Femenino
                                    </MenuItem>
                                    <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="M"
                                    >
                                    Masculino
                                    </MenuItem>
                                </Select>
                        </FormControl>
                        <p className="p_require"> {errors.gender && errors.gender} </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="healthForecast"
                            className={classes.selectLabel}
                          >
                            Previsión de salud
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={healthForecast ? healthForecast : ''}
                            onChange={handlerChange}
                            inputProps={{
                              name: "healthForecast",
                              id: "healthForecast",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              value="Sin prevision"
                            >
                              Previsión de salud
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Fonasa"
                            >
                              Fonasa
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Colmena"
                            >
                              Isapre Colmena
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Cruz Blanca"
                            >
                              Isapre Cruz Blanca
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Banmédica"
                            >
                              Isapre Banmédica
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Consalud"
                            >
                              Isapre Consalud
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Vida tres"
                            >
                              Isapre Vida tres
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Nueva masvida"
                            >
                              Isapre Nueva masvida
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Isapre Esencial"
                            >
                              Isapre Esencial
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <p className="p_require">{errors.healthForecast && errors.healthForecast}</p>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} className='grid-birthday'>
                        <FormControl fullWidth>
                          <Datetime
                            locale="es"
                            onChange={handlerDateChange}
                            timeFormat={false}
                            inputProps={inputProps}
                            className='patient-birthday'
                          />
                          <p className="p_require">{errors.birthday && errors.birthday}</p>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <GridContainer>                
                  <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Dirección"
                        id="address_0"
                        onChange={handlerChange}
                        name="street"
                        value={street && street !== 'Sin Calle' ? street : ''}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      <p className="p_require">
                        {errors.street && errors.street}
                      </p>
                  </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                    <FormControl
                      fullWidth
                      className={`${classes.selectFormControl}`}
                    >
                      <InputLabel
                        htmlFor="region"
                        className={`${classes.selectLabel} label-custom-reg`}
                      >
                        Región
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: `${classes.selectMenu}`,
                        }}
                        classes={{
                          select: `${classes.select} select-custom-reg`,
                        }}
                        value={region ? region : ''}
                        onChange={handlerChangeReg}
                        inputProps={{
                          name: "region",
                          id: "region",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          value=""
                        >
                          Selecione una región
                        </MenuItem>
                        {stateReg && Object.keys(stateReg).length > 0 ? (
                          stateReg.map(reg => (
                            <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={reg.name}
                            key={reg.id}
                          >
                            {reg.name}
                          </MenuItem>
                          ))
                        ) : null}
                      </Select>
                    </FormControl>
                    <p className="p_require">{errors.region && errors.region}</p>
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={3}>
                    <FormControl
                      fullWidth
                      className={`${classes.selectFormControl}`}
                    >
                      <>  
                        <InputLabel
                          htmlFor="commune"
                          className={`${classes.selectLabel} label-custom-reg`}
                          >
                          Comuna / Provincia
                        </InputLabel>
                        <Select
                          MenuProps={{
                              className: `${classes.selectMenu}`,
                            }}
                            classes={{
                                select: `${classes.select} select-custom-reg`,
                            }}
                            value={commune ? commune : ''}
                            onChange={handlerChange}
                            inputProps={{
                            name: "commune",
                            id: "commune",
                        }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                                root: classes.selectMenuItem,
                            }}
                            value="Sin Comuna"
                            >
                            Selecione una Comuna
                          </MenuItem>
                            {comunes && Object.keys(comunes).length > 0 ? (
                                comunes.map(comuna => (
                                <MenuItem
                                classes={{
                                    root: `${classes.selectMenuItem}`,
                                    selected: `${classes.selectMenuItemSelected} menuItem-capitalize`
                                }}
                                value={comuna.name}
                                key={comuna.id}
                                >
                                {comuna.name}
                                </MenuItem>
                            ))) : null}
                        </Select>
                      </>
                    </FormControl>
                    <p className="p_require">{errors.commune && errors.commune}</p>
                  </GridItem> 
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} className="center-select">
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                            <InputLabel
                            htmlFor="company"
                            className={classes.selectLabel}
                            >
                            Empresa
                            </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={company ? company._id : ''}
                                    onChange={handlerChange}
                                    inputProps={{
                                        name: "company",
                                        id: "company",
                                    }}
                                >
                                    <MenuItem
                                      disabled
                                      classes={{
                                          root: classes.selectMenuItem,
                                      }}
                                      value=""
                                    >
                                    Seleccione una Empresa
                                    </MenuItem>
                                    <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value={company ? company._id : ''}
                                    >
                                    {company ? company.name: ''}
                                    </MenuItem>
                                </Select>
                            <p className="p_require">{errors.company && errors.company}</p>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} className="center-select">
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                            <InputLabel
                            htmlFor="branchOffice"
                            className={classes.selectLabel}
                            >
                            Sucursal
                            </InputLabel>
                            <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={branchOffice ? branchOffice : ''}
                                    onChange={handlerChange}
                                    inputProps={{
                                        name: "branchOffice",
                                        id: "branchOffice",
                                    }}
                                >
                                    <MenuItem
                                      disabled
                                      classes={{
                                          root: classes.selectMenuItem,
                                      }}
                                      value=""
                                    >
                                    Seleccione una Sucursal
                                    </MenuItem>
                                    {company && company?.branchesOffices?.length > 0 ? (
                                      company.branchesOffices.map(branch => (
                                        <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected,
                                        }}
                                        value={String(branch._id)}
                                        key={branch._id}
                                      >
                                        {branch.name}
                                      </MenuItem>
                                      ))
                                    ) : null}
                                </Select>
                            <p className="p_require">{errors.company && errors.company}</p>
                        </FormControl>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
 
export default InputsPatient;