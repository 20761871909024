
export const SiteRoutes = {
  Login: { path: '/login' },
  Poll: { path: '/encuestas' },
  Profile: { path: '/'},
  Sampling: { path: '/toma-muestra'},
  ValidateIdentity: { path: '/validar-identidad'}
}

export const MenuOptions = [
  {
    path: "/",
    name: "Datos personales",
    mini: "DP",
    layout: '/admin',
  },
  {
  path: "/encuestas",
  name: "Encuestas",
  mini: "EN",
  layout: '/admin',
  },
  {
    path: "/toma-muestra",
    name: "Toma de muestra",
    mini: "TM",
    layout: '/admin',
  },
  {
    path: "/validar-identidad",
    name: "Validar Identidad",
    mini: "VI",
    layout: '/admin',
  },
];