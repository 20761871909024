import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import HealthBenefitsInputs from "happ-components/Operatives/HealthBenefitsInputs";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);


const HealthBenefits = ({currentOperative, ...rest}) => {
    const [checkedA, setCheckedA] = useState(true);
    const [checkedB, setCheckedB] = useState(true);
    const [checkedC, setCheckedC] = useState(true);
    const [checkedD, setCheckedD] = useState(true);
    const [checkedE, setCheckedE] = useState(true);
    const [checkedF, setCheckedF] = useState(true);
    const classes = useStyles();
    const msg_prev_results = useSelector(state => state.operative.msg_prev_results);
    const {healthBenefits} = currentOperative;
    const {onlyPoll, title, msgOk} = rest;

    return ( 
        <div className="content-health-benefits">
            {msg_prev_results ? (
                <p className="error_patient">
                    {msg_prev_results}
                </p>
            ) : null}
            {msgOk ? (
                <p className="error_patient">
                    {msgOk}
                </p>
            ) : null}
            <h3>{title}</h3>
            {onlyPoll ?
            (
                healthBenefits ? healthBenefits.map(healthBenefit => (
                    healthBenefit.type === 'encuesta' ? (
                        <GridContainer className="health-befenefit" key={healthBenefit._id}>
                            <GridItem xs={12} sm={12} md={4} className="first-item-health-befenefit">
                                <div className={classes.block}>
                                    <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkedA}
                                            onChange={(event) =>
                                                setCheckedA(event.target.checked)
                                            }
                                            value="checkedA"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar,
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label={healthBenefit.name}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8} className="second-item-health-befenefit">
                                <HealthBenefitsInputs
                                    {...rest} 
                                    healthBenefit={healthBenefit}
                                    classes={classes}
                                />
                            </GridItem>
                        </GridContainer>
                    ) : null
                )) : <p>No hay encuestas disponibles</p>
            ) : (
                healthBenefits ? healthBenefits.map(healthBenefit => (
                    healthBenefit.type !== 'encuesta' ? (
                        <GridContainer className="health-befenefit" key={healthBenefit._id}>
                            <GridItem xs={12} sm={12} md={4} className="first-item-health-befenefit">
                                <div className={classes.block}>
                                    <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkedA}
                                            onChange={(event) =>
                                                setCheckedA(event.target.checked)
                                            }
                                            value="checkedA"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar,
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label={healthBenefit.name}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8} className="second-item-health-befenefit">
                                <HealthBenefitsInputs
                                    {...rest} 
                                    healthBenefit={healthBenefit}
                                    classes={classes}
                                />
                            </GridItem>
                        </GridContainer>
                    ) : null
                )) : null
            )}
        </div>
    );
}
 
export default HealthBenefits;