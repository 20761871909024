// Login
export const INIT_SESSION = 'INIT_SESSION';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const INIT_LOGOUT = 'INIT_LOGOUT';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

// Profile
export const ME = 'ME';

// General
export const SET_INIT_VIEW = 'SET_INIT_VIEW';

// Operative
export const INIT_OPERATIVE = 'INIT_OPERATIVE';
export const OPERATIVE = 'OPERATIVE';
export const OPERATIVE_ERROR = 'OPERATIVE_ERROR';
export const CURRENT_OPERATIVE = 'CURRENT_OPERATIVE';

export const INIT_GET_PATIENT_BY_DNI = 'INIT_GET_PATIENT_BY_DNI';
export const GET_PATIENT_BY_DNI = 'GET_PATIENT_BY_DNI';
export const GET_PATIENT_BY_DNI_ERROR = 'GET_PATIENT_BY_DNI_ERROR';

export const INIT_SAVE_PATIENT = 'INIT_SAVE_PATIENT';
export const SAVE_PATIENT = 'SAVE_PATIENT';
export const SAVE_PATIENT_ERROR = 'SAVE_PATIENT_ERROR';

export const INIT_GET_PATIENT_RESULTS_BY_DNI = 'INIT_GET_PATIENT_RESULTS_BY_DNI'
export const GET_PATIENT_RESULTS_BY_DNI = 'GET_PATIENT_RESULTS_BY_DNI';
export const GET_PATIENT_RESULTS_BY_DNI_ERROR = 'GET_PATIENT_RESULTS_BY_DNI_ERROR';

export const INIT_SAVE_PATIENT_RESULTS_BY_DNI = 'INIT_SAVE_PATIENT_RESULTS_BY_DNI'
export const SAVE_PATIENT_RESULTS_BY_DNI = 'SAVE_PATIENT_RESULTS_BY_DNI';
export const SAVE_PATIENT_RESULTS_BY_DNI_ERROR = 'SAVE_PATIENT_RESULTS_BY_DNI_ERROR';

export const SET_CREATE_STATUS = 'SET_CREATE_STATUS';

export const INIT_GET_LAST_POLL_RESULTS = 'INIT_GET_LAST_POLL_RESULTS';
export const GET_LAST_POLL_RESULTS = 'GET_LAST_POLL_RESULTS';
export const GET_LAST_POLL_RESULTS_ERROR = 'GET_LAST_POLL_RESULTS_ERROR';

export const INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI = 'INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI'
export const SAVE_PATIENT_POLL_RESULTS_BY_DNI = 'SAVE_PATIENT_POLL_RESULTS_BY_DNI';
export const SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR = 'SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR';

export const INIT_VERIFICATE_DNI_IMED = 'INIT_VERIFICATE_DNI_IMED'
export const VERIFICATE_DNI_IMED = 'VERIFICATE_DNI_IMED';
export const VERIFICATE_DNI_IMED_ERROR = 'VERIFICATE_DNI_IMED_ERROR';

export const INIT_CERTIFICATION_DNI_IMED = 'INIT_CERTIFICATION_DNI_IMED'
export const CERTIFICATION_DNI_IMED = 'CERTIFICATION_DNI_IMED';
export const CERTIFICATION_DNI_IMED_ERROR = 'CERTIFICATION_DNI_IMED_ERROR';