
export default function validateFormInputs(inputs){
    
    let errors = {};

    //Datos personales
    if( ('email' in inputs) && (inputs.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) ){
        errors.email = 'El email es requerido o ingreso un mail no valido';
    } 
    if( ('password' in inputs) && (inputs.password === '') ) {
        errors.password = 'La contraseña debe ser con un minimo de 6 digitos';
    }
    if ( ('firstName' in inputs) && inputs.firstName === '' ){
        errors.firstName = 'El nombre es requerido'
    }
    if ( ('lastName' in inputs) && inputs.lastName === '' ){
        errors.lastName = 'El apellido es requerido'
    }
    if ( ('dni' in inputs) && inputs.dni === ''){
        errors.dni = 'El rut es requerido ó no es valido'
    }
    if( ('gender' in inputs) && inputs.gender === 'Sin genero' ) {
        errors.gender = 'El genero es requerido';
    }
    if( ('phone' in inputs) && inputs.phone === '' ) {
        errors.phone = 'EL número de teléfono es requerido';
    }
    if( ('region' in inputs) && inputs.region === '' ) {
        errors.region = 'Seleccione una región valida';
    }    
    if( ('commune' in inputs) && inputs.commune === 'Sin Comuna' ) {
        errors.commune = 'Seleccione una comuna valida';
    }
    if( ('street' in inputs) && inputs.street === 'Sin Calle' ) {
        errors.street = 'Ingrese una direccion de domicilio';
    }
    if( ('birthday' in inputs) && inputs.birthday === '' ) {
        errors.birthday = 'Seleccione su fecha de nacimiento';
    }
    if( ('healthForecast' in inputs) && inputs.healthForecast === 'Sin prevision' ) {
        errors.healthForecast = 'Seleccione su previsión de salud';
    }
    if( ('company' in inputs) && inputs.company === '' ) {
        errors.company = 'Seleccione una empresa';
    }
    if( ('branchOffice' in inputs) && inputs.branchOffice === '' ) {
        errors.branchOffice = 'Seleccione una sucursal';
    }
    if( ('position' in inputs) && inputs.position === '' ) {
        errors.position = 'Seleccione un cargo';
    }

    //IMC
    if( ('peso' in inputs) && (inputs.peso === '') ) {
        errors.peso = 'El campo no puede estar vacio';
    }
    if( ('peso' in inputs) && (inputs.peso === ''  || !/^[0-9]+$/.test(inputs.peso)) ) {
        errors.peso = 'Solo Número';
    }
    if( inputs.peso < 30 || inputs.peso > 200 ) {
        errors.peso = 'Fuera de rango: entre 30kg y 200kg';
    }

    if( ('altura' in inputs) && (inputs.altura === '') ) {
        errors.altura = 'Solo Número';
    }
    if( ('altura' in inputs) && (inputs.altura < 100 || inputs.altura > 250) ) {
        errors.altura = 'Fuera de rango: entre 100cms y 250cms';
    }

    if( ('imc' in inputs) && (inputs.imc === '' || !/^[0-9]+$/.test(inputs.imc)) ) {
        errors.imc = 'Solo Número';
    }

    //Circunferencia abdominal
    if( ('circunferencia' in inputs) && (inputs.circunferencia === '' || !/^[0-9]+$/.test(inputs.circunferencia)) ) {
        errors.circunferencia = 'Solo Número';
    }
    
    //Glicemia
    if( ('glicemia' in inputs) && (inputs.glicemia === '' || !/^[0-9]+$/.test(inputs.glicemia)) ) {
        errors.glicemia = 'Solo Número';
    }
    // if( ('glicemia' in inputs) && (inputs.glicemia < 70 || inputs.glicemia > 100) ) {
    //     errors.glicemia = 'Fuera de rango: entre 70 y 100';
    // }

    //Perfil lipidico
    if( ('colesterol' in inputs) && (inputs.colesterol === '' || !/^[0-9]+$/.test(inputs.colesterol)) ) {
        errors.colesterol = 'Solo Número';
    }
    if( ('colesterol' in inputs) && (inputs.colesterol < 10 || inputs.colesterol > 600) ) {
        errors.colesterol = 'Fuera de rango: entre 10 y 600';
    }
    if( ('trigliceridos' in inputs) && (inputs.trigliceridos === '' || !/^[0-9]+$/.test(inputs.trigliceridos)) ) {
        errors.trigliceridos = 'Solo Número';
    }
    if( ('trigliceridos' in inputs) && (inputs.trigliceridos < 10 || inputs.trigliceridos > 600) ) {
        errors.trigliceridos = 'Fuera de rango: entre 10 y 600';
    }

    //Presion arterial
    if( ('sistolica_bi' in inputs) && (inputs.sistolica_bi === '' || !/^[0-9]+$/.test(inputs.sistolica_bi)) ) {
        errors.sistolica_bi = 'Solo Número';
    }
    if( ('sistolica_bi' in inputs) && (inputs.sistolica_bi < 40 || inputs.sistolica_bi > 250) ) {
        errors.sistolica_bi = 'Fuera de rango: entre 40 y 250';
    }

    if( ('diastolica_bi' in inputs) && (inputs.diastolica_bi === '' || !/^[0-9]+$/.test(inputs.diastolica_bi)) ) {
        errors.diastolica_bi = 'Solo Número';
    }
    if( ('diastolica_bi' in inputs) && (inputs.diastolica_bi < 20 || inputs.diastolica_bi > 200) ) {
        errors.diastolica_bi = 'Fuera de rango: entre 20 y 200';
    }

    if( ('sistolica_de' in inputs) && (inputs.sistolica_de === '' || !/^[0-9]+$/.test(inputs.sistolica_de)) ) {
        errors.sistolica_de = 'Solo Número';
    }
    if( ('sistolica_de' in inputs) && (inputs.sistolica_de < 40 || inputs.sistolica_de > 250) ) {
        errors.sistolica_de = 'Fuera de rango: entre 40 y 250';
    }

    if( ('diastolica_de' in inputs) && (inputs.diastolica_de === '' || !/^[0-9]+$/.test(inputs.diastolica_de)) ) {
        errors.diastolica_de = 'Solo Número';
    }
    if( ('diastolica_de' in inputs) && (inputs.diastolica_de < 20 || inputs.diastolica_de > 200) ) {
        errors.diastolica_de = 'Fuera de rango: entre 20 y 200';
    }

    //Comentarios
    if( ('comentarios' in inputs) && (inputs.comentarios).length > 130 ) {
        errors.comentarios = 'Ingrese un comentario de maximo 130 caracteres';
    }

    return errors;
}