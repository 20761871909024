import {
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400",
    },
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
  },
  description: {
    color: grayColor[0],
  },
  backButton: {
    background: "#0050ff !important", 
    float: "right",
  },
  iconBack: {
    background: "#0050ff !important",
    float: "left",
    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    borderRadius: "3px"
  }
};
export default userProfileStyles;
