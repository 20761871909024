import {
    SET_INIT_VIEW,
    INIT_OPERATIVE,
    OPERATIVE,
    OPERATIVE_ERROR,
    CURRENT_OPERATIVE,
    INIT_GET_PATIENT_BY_DNI,
    GET_PATIENT_BY_DNI,
    GET_PATIENT_BY_DNI_ERROR,
    INIT_GET_PATIENT_RESULTS_BY_DNI,
    GET_PATIENT_RESULTS_BY_DNI,
    GET_PATIENT_RESULTS_BY_DNI_ERROR,
    INIT_SAVE_PATIENT_RESULTS_BY_DNI,
    SAVE_PATIENT_RESULTS_BY_DNI,
    SAVE_PATIENT_RESULTS_BY_DNI_ERROR,
    SET_CREATE_STATUS,
    INIT_SAVE_PATIENT,
    SAVE_PATIENT,
    SAVE_PATIENT_ERROR,
    INIT_GET_LAST_POLL_RESULTS,
    GET_LAST_POLL_RESULTS,
    GET_LAST_POLL_RESULTS_ERROR,
    INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI,
    SAVE_PATIENT_POLL_RESULTS_BY_DNI,
    SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR,
    INIT_VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED_ERROR,
    INIT_CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED_ERROR
}  from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    currentOperativeSampling: null,
    currentOperativePoll: null,
    currentOperativeProfile: null,
    operatives: null,
    patient: null,
    currentsResults: null,
    msg_error_currents_results: null,
    msg_prev_results: null,
    msg_patient: null,
    form_inputs_results: null,
    msg_server: null,
    create_status: false,
    okInputs: false,
    pureQuestions: null,
    msgOk: null,
    select: false,
    disabled: true,
    questionsObj: null,
    arrayPos: null,
    error_operatives: null,
    input_patient_company: null,
    urlVerificateIdentity: null,
    errorEnrollment: null,
    certificateOk: null,
    certificateDni: null,
    certificateErrorMsg: null,
}


export default function(state = initialState, action){
    switch(action.type){
        case SET_INIT_VIEW:
            return {
                ...state,
                loading: false,
                currentOperativeSampling: null,
                currentOperativePoll: null,
                currentOperativeProfile: null,
                only_poll: null,
                select: true,
                okInputs: false,
                errorEnrollment: null,
                patient: null,
                urlVerificateIdentity: null,
                disabled: true,
                currentsResults: null,
                msg_error_currents_results: null,
                msg_prev_results: null,
                msg_patient: null,
                form_inputs_results: null,
                certificateOk: null,
                certificateDni: null,
                certificateErrorMsg: null,
                msg_server: null,
                create_status: false,
                error_operatives: null,
                pureQuestions: null,
                questionsObj: null,
                msgOk: null,
                arrayPos: null,
                input_patient_company: null,
            } 
        case INIT_OPERATIVE:
        case INIT_GET_PATIENT_BY_DNI:    
        case INIT_GET_PATIENT_RESULTS_BY_DNI:
        case INIT_SAVE_PATIENT_RESULTS_BY_DNI:
        case INIT_SAVE_PATIENT:
        case INIT_VERIFICATE_DNI_IMED:    
        case INIT_CERTIFICATION_DNI_IMED:
        case INIT_GET_LAST_POLL_RESULTS:
        case INIT_SAVE_PATIENT_POLL_RESULTS_BY_DNI:
            return {
                ...state,
                loading: true,
                select: false,
            }
        case OPERATIVE:
            return {
                ...state,
                operatives: action.payload,
                select: false,
                loading: false,
                error_operatives: null
            }
        case OPERATIVE_ERROR:
            return {
                ...state,
                loading: false,
                operatives: null,
                select: false,
                error_operatives: action.payload
            }   
        case CURRENT_OPERATIVE:
            if (action.payload.view === 'poll'){
                return {
                    ...state,
                    currentOperativePoll: action.payload.operative,
                    select: false,
                    only_poll: action.payload.onlyPoll
                }         
            }
            if (action.payload.view === 'sampling'){
                return {
                    ...state,
                    select: false,
                    currentOperativeSampling: action.payload.operative,
                }         
            }
            if (action.payload.view === 'profile'){
                return {
                    ...state,
                    select: false,
                    currentOperativeProfile: action.payload.operative,
                }         
            }
            case SAVE_PATIENT_RESULTS_BY_DNI:   
            return {
                ...state,
                patient: null,
                currentsResults: null,
                loading: false,
                select: false,
                okInputs: false,
                disabled: true,
                msg_error_currents_results: null,
                msg_prev_results: null,
                msg_server: null,
                create_status: true
            }  
            case SAVE_PATIENT_RESULTS_BY_DNI_ERROR:
                return{
                    ...state,
                    patient: null,
                    currentsResults: null,
                    disabled: true,
                    okInputs: false,
                    loading: false,
                    select: false,
                    msg_error_currents_results: null,
                    msg_prev_results: null,
                    msg_server: action.payload,
                    create_status: false
                }      
        case GET_PATIENT_BY_DNI:
            return {
                ...state,
                loading: false,
                patient: action.payload,
                okInputs: true,
                msg_patient: null,
                disabled: false,
                select: false,
                input_patient_company: null,
            }
        case GET_PATIENT_BY_DNI_ERROR:
            return {
                ...state,
                loading: false,
                patient: null,
                okInputs: true,
                select: false,
                disabled: false,
                msg_patient: action.payload,
                input_patient_company: state.currentOperativeProfile
            }  
        case GET_PATIENT_RESULTS_BY_DNI:
            return {
                ...state,
                currentsResults: action.payload.existingHealthBenefitResultsPatient,
                patient: action.payload.existingPatient[0],
                form_inputs_results: action.payload.inputsForms,
                msg_prev_results: action.payload.infoPatient,
                loading: false,
                okInputs: true,
                select: false,
                disabled: false,
                msg_error_currents_results: null
            }
            case GET_PATIENT_RESULTS_BY_DNI_ERROR:
                if ( action.payload.block && action.payload.block === 1 ) {
                    return {
                        ...state,
                        currentsResults: null,
                        loading: false,
                        okInputs: false,
                        select: false,
                        msg_error_currents_results: action.payload.msg,
                        disabled: true,
                        form_inputs_results: null,
                        currentOperativeSampling: null
                    }               
                } else if ( action.payload.block && action.payload.block === 2 ){
                    return {
                        ...state,
                        currentsResults: null,
                        loading: false,
                        okInputs: true,
                        select: false,
                        form_inputs_results: null,
                        disabled: false,
                        patient: action.payload.existingPatient[0],
                        msg_prev_results: action.payload.infoPatient,
                    }   
                } else {
                    return {
                        ...state,
                        currentsResults: null,
                        loading: false,
                        form_inputs_results: null,
                        patient: null,
                        okInputs: false,
                        disabled: true,
                        select: false,
                        msg_prev_results: null,
                        msg_server: action.payload
                    }   
                }
            case SET_CREATE_STATUS:
                    return {
                        ...state,
                        select: false,
                        create_status: false
                    }    
            case SAVE_PATIENT:
                return {
                    ...state,
                    patient: null,
                    loading: false,
                    select: false,
                    disabled: true,
                    okInputs: false,
                    msg_error_currents_results: null,
                    msg_prev_results: null,
                    msg_patient: null,
                    msg_server: null,
                    create_status: true,
                    input_patient_company: null
                }       
            case SAVE_PATIENT_ERROR:
                return{
                    ...state,
                    patient: null,
                    loading: false,
                    select: false,
                    disabled: true,
                    okInputs: false,
                    msg_error_currents_results: null,
                    msg_prev_results: null,
                    msg_patient: action.payload,
                    msg_server: null,
                    create_status: false,
                    input_patient_company: null
                }
            case SAVE_PATIENT_POLL_RESULTS_BY_DNI:
                return {
                    ...state,
                    loading: false,
                    select: false,
                    msg_error_currents_results: null,
                    msg_prev_results: null,
                    msg_server: null,
                    create_status: true,
                    okInputs: false,
                    disabled: true,
                    pureQuestions: null,
                    questionsObj: null,
                    arrayPos: null,
                    patient: null
                }
            case SAVE_PATIENT_POLL_RESULTS_BY_DNI_ERROR:
                return {
                    ...state,
                    loading: false,
                    select: false,
                    msg_error_currents_results: null,
                    msg_prev_results: null,
                    msg_server: null,
                    okInputs: false,
                    disabled: true,
                    create_status: false,
                    pureQuestions: null,
                    questionsObj: null,
                    arrayPos: null,
                    patient: null
                }        
            case GET_LAST_POLL_RESULTS:
                return {
                    ...state,
                    loading: false,
                    select: false,
                    msg_prev_results: null,
                    disabled: false,
                    okInputs: true,
                    msgOk: action.payload.ok,
                    questionsObj: action.payload.questionsObj,
                    pureQuestions: action.payload.pureQuestions,
                    patient: action.payload.existingPatient[0],
                    arrayPos: action.payload.arrayPos
                }
            case GET_LAST_POLL_RESULTS_ERROR:
                if ( action.payload.block && action.payload.block === 1 ) {
                    return {
                        ...state,
                        loading: false,
                        select: false,
                        disabled: true,
                        okInputs: false,
                        msgOk: null,
                        msg_error_currents_results: action.payload.msg,
                        currentOperativePoll: null,
                        questionsObj: null,
                        pureQuestions: null
                    }                 
                } else if ( action.payload.block && action.payload.block === 2 ){
                    return {
                        ...state,
                        loading: false,
                        select: false,
                        okInputs: true,
                        disabled: false,
                        msg_error_currents_results: null,
                        patient: action.payload.existingPatient[0],
                        msg_prev_results: action.payload.infoPatient,
                        questionsObj: action.payload.questionsObj,
                        pureQuestions: action.payload.pureQuestions
                    }   
                } else {
                    return {
                        ...state,
                        currentsResults: null,
                        loading: false,
                        select: false,
                        okInputs: false,
                        form_inputs_results: null,
                        patient: null,
                        msg_prev_results: null,
                        msg_server: action.payload
                    }   
                } 
            case VERIFICATE_DNI_IMED:
                return {
                    ...state,
                    urlVerificateIdentity: action.payload.data.url_redireccion
                }
            case VERIFICATE_DNI_IMED_ERROR:
                return {
                    ...state,
                    urlVerificateIdentity: null,
                    errorEnrollment: action.payload
                }
            case CERTIFICATION_DNI_IMED:
                return {
                    ...state,
                    certificateOk: action.payload.msg,
                    certificateDni: action.payload.dni,
                    certificateErrorMsg: null,
                }
            case CERTIFICATION_DNI_IMED_ERROR:
                return {
                    ...state,
                    certificateOk: null,
                    certificateDni: null,
                    certificateErrorMsg: action.payload,
                }                     
        default:
            return state
    }
}    