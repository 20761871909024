import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import {getPatient, getLastResultPatient, getLastPollResultPatient} from "happ-redux/happ-actions/operative/operativeActions";
import {validateRut} from 'happ-helpers/validateRut';
import { checkRut } from "react-rut-formatter";

const InputDni = ({classes, ...rest}) => {
    const msg_patient = useSelector(state => state.operative.msg_patient);
    const currentOperativeSampling = useSelector(state => state.operative.currentOperativeSampling);
    const currentOperativePoll = useSelector(state => state.operative.currentOperativePoll);
    const only_poll = useSelector(state => state.operative.only_poll);
    const [localDni, setLocalDni] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const { 
        handlerChange,
        handlerChangeDni,
        state,
        errors,
    } = rest;
    const { dni } = state;
 
    const handlerClickDni = e => {
        
        e.preventDefault();
        if (dni === '' || (!checkRut(dni))){
            setLocalDni(true);
            return;
        }
        setLocalDni(false);
        if (location.pathname === '/') {
            dispatch(getPatient(dni.toLowerCase()));
        }
        if (location.pathname === '/toma-muestra') {
            const {_id, operativeDate} = currentOperativeSampling;
            dispatch(getLastResultPatient(dni.toLowerCase(), _id, operativeDate));
        }
        if (location.pathname === '/encuestas') {
            const {code} = only_poll[0];
            dispatch(getLastPollResultPatient(dni.toLowerCase(), code));
        }
    }  
    return ( 
        <div className="content-inputs-patients">
            <div className="content-dni">
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    labelText="Rut Ej: 11111111-1"
                    id="dni"
                    name="dni"
                    placeholder=""
                    onChange={handlerChangeDni}
                    value={dni ? dni : ''}
                    formControlProps={{
                        fullWidth: true,
                    }}
                />

                <p className="p_require">{errors.dni && errors.dni}</p>
                {localDni ? (
                    <p className="p_require">El rut no es valido</p>
                ) : null}
                {msg_patient && msg_patient}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <Button
                    className={`${classes.backButton} happ-background-color color-white btn-search-dni`}
                    type="button"
                    onClick={handlerClickDni}
                >
                    Buscar
                </Button>
                </GridItem>
            </GridContainer>    
            </div>
        </div>      
    );
}
 
export default InputDni;