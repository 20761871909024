import { tm } from 'happ-preloaded-data/data';
import axiosClient from 'happ-config/axios';

const verificateLogin = async (login_data) => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post('/auths/admin', login_data, {
            headers: headers,
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const loginTrue = (token) => {
    if ( token !== null ) {
        // validar tiempo de expericaión de token
        return true;
    }
    return false;
}

const getProfile = (token) => {
    if ( token !== null ) {
        // validar tiempo de expericaión de token
        const meProfile = {... tm}
        return meProfile;
    }
    return null;
}

export  {
    verificateLogin,
    getProfile,
    loginTrue
}