import { regiones, comunas, operatives } from 'happ-preloaded-data/data';
import axiosClient from 'happ-config/axios';

const communesByReg = region => {
    const id = getIdRegByName(region);
    if ( id !== null ) {
        return comunas.filter(comuna => comuna.reg === id);
    } 
    return null;   
}

const getIdRegByName = region => {
    if ( region !== null ) {
        let id = 0;
        regiones.some(reg => {
            if (reg.name === region) {
                id = reg.id;
            }
        })
        return id;
    }
    return null;
}

const getOperativesByDate = async (token, today) => {

    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`/operatives/getOperativesToday/${token}/${today}`, {
            headers: headers,
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
    
}

const findPatientByDni = async dni => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`/patients/getByDni/${dni}`, {
            headers: headers,
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getLastResultTest = async (dni, operative, operativeDate) => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`/healthbenefitresults/getHealthBenefits/${dni}/${operative}/${operativeDate}`, {
            headers: headers,
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getLastResultsPoll = async (dni, pack) => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`surveys/getLastResult/${dni}/${pack}`, {
            headers: headers,
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getInputnames = (state, operativeInputs) => {
    const inputsClean = operativeInputs.map(input => input.nroInputs);
    const flatInputs = inputsClean.reduce((acc, el) => acc.concat(el), []);
    const newValuesForm = flatInputs.map(item => item.name).reduce((acc, el) => {
        acc[el] = '';
        return acc;
    },{}); 
    return {...state, ...newValuesForm};
}

const getOnlyPoll = operativeInputs => {
    const questions = operativeInputs.filter(item => item.type === 'encuesta');
    if (questions.length > 0){
        return questions;
    }
    return null;
}

const sendSurveyPatient = async survey => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post('/surveys/create', survey, {
            headers: headers
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const prepareStructurePollResults = (patient, state, poll) => {
    let structureCreate = {};
    const {_id, gender, birthDay} = patient;
    const {name, code} = poll;
    const {encuesta_rcv} = state;
    structureCreate.patient = _id;
    structureCreate.patientAge = birthDay;
    structureCreate.patientGender = gender,
    structureCreate.surveyCode = code;
    structureCreate.surveyName = name;
    structureCreate.results = encuesta_rcv;
    return structureCreate;
}
const prepareStructureResults = (patient, state) => {
    let structureCreate = {};
    const {_id, operativeDate, laboratory, healthBenefits} = state.operative;
    const heightInMeters = state.altura / 100
    const imc = Math.round(Math.round(state.peso / (heightInMeters * heightInMeters) * 100) / 100);
    let arraystructureResults = [];
    healthBenefits.map(item => {
        if (item.code !== 'RCV001'){
            if (item.code === 'GL001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitType: item.type,
                  healthBenefitName: item.name,
                  healthBenefitCode: item.code,
                  results : [
                    {
                      "item": "ayunas",
                      "value": Boolean(state.ayunas),
                      "status": "confirmed",
                      "confirmedDate": operativeDate
                    },
                    {
                      "item": "glicemia",
                      "value": Number(state.glicemia),
                      "status": "confirmed",
                      "confirmedDate": operativeDate
                    },
                  ]
                })
                
              }
              if (item.code === 'IMC001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitName: item.name,
                  healthBenefitType: item.type,
                  healthBenefitCode: item.code,
                  results : [
                    {
                        "item": "peso",
                        "value": Number(state.peso),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    },
                    {
                        "item": "altura",
                        "value": parseFloat(state.altura),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    },
                    {
                        "item": "imc",
                        "value": parseFloat(imc),
                        "status": "confirmed",
                        "confirmedDate": operativeDate,
                    },
                    {
                        "item": "deportista",
                        "value": Boolean(state.deportista),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                      },
                  ]
                })
                
              }
              if (item.code === 'PL001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitName: item.name,
                  healthBenefitType: item.type,
                  healthBenefitCode: item.code,
                  results : [
                    {
                        "item": "colesterol",
                        "value": Number(state.colesterol),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    },
                    {
                        "item": "trigliceridos",
                        "value": parseFloat(state.trigliceridos),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    }
                  ]
                })
                
              }
              if (item.code === 'PA001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitName: item.name,
                  healthBenefitType: item.type,
                  healthBenefitCode: item.code,
                  results : [
                    {
                        "item": "sistolica_bi",
                        "value": Number(state.sistolica_bi),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    },
                    {
                        "item": "diastolica_bi",
                        "value": parseFloat(state.diastolica_bi),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    }
                  ]
                });
              }
              if (item.code === 'CIA001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitName: item.name,
                  healthBenefitType: item.type,
                  healthBenefitCode: item.code,
                  results : [
                    {
                        "item": "circunferencia",
                        "value": Number(state.circunferencia),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    }
                  ]
                });
              }
              if (item.code === 'TXT001'){
                arraystructureResults.push({
                  healthBenefit: item._id,
                  healthBenefitName: item.name,
                  healthBenefitType: item.type,
                  healthBenefitCode: item.code,
                  results : [
                    {
                        "item": "comentarios",
                        "value": String(state.comentarios),
                        "status": "confirmed",
                        "confirmedDate": operativeDate
                    }
                  ]
                });
              }
        }
    });
    structureCreate.sessionAdminId = localStorage.getItem('token');
    structureCreate.patient = patient._id;
    structureCreate.dni = patient.dni;
    structureCreate.operative = _id;
    structureCreate.laboratory = laboratory._id;
    structureCreate.operativeResultsDate = operativeDate;
    structureCreate.type = 'Operative Happ';
    structureCreate.healthBenefitResults = arraystructureResults;    
    return structureCreate;
}


const sendHealthBenefitsResults = async structureCreate => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post('/healthbenefitresults/create', structureCreate, {
            headers: headers
        });
        return response
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const sendnewPatient = async patient => {
    try {
        const headers = {'Content-Type': 'application/json'}
        const response = await axiosClient.post('/patients/createPatientTester', patient, {
            headers: headers
        });
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const verificateDniImed = async dni => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`enrollment/imed-verification/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const certificateDniImed = async body => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post(`enrollment/imed-certification`, body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    regiones,
    communesByReg,
    getOperativesByDate,
    getInputnames,
    findPatientByDni,
    getOnlyPoll,
    getLastResultTest,
    prepareStructureResults,
    sendHealthBenefitsResults,
    sendnewPatient,
    getLastResultsPoll,
    prepareStructurePollResults,
    sendSurveyPatient,
    verificateDniImed,
    certificateDniImed
}