import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import stylesDash from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import '../Operative/Operative.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert2 from 'react-sweetalert2';

//happ-components
import SelectOperative from 'happ-components/Operatives/SelectOperative'; 
import InputDni from 'happ-components/Operatives/InputDni';
import HealthBenefits from 'happ-components/Operatives/HealthBenefits';


//happ-icons 
import { IconIM } from "happ-components/happ-icons/icons";

import {getInitOperatives, savePollResults} from 'happ-redux/happ-actions/operative/operativeActions';
import useValidatorforms from "happ-custom-hooks/useValidatorforms";


const stylesPanel = {
    cardTitle,
    pageSubcategoriesTitle: {
      color: "#3C4858",
      textDecoration: "none",
      textAlign: "center",
    },
    cardCategory: {
      margin: "0",
      color: "#999999",
    },
  };

const ProfileStyle = makeStyles(styles);  
const useStyles = makeStyles(stylesPanel);
const dashStyles = makeStyles(stylesDash);

let pollInitState = {
    operative: '',
    dni: '',
    encuesta_rcv: [],
    encuesta_pos: [],
  };

const Poll = () => {
    const classes = useStyles();
    const ProfileClass = ProfileStyle();
    const DashClass = dashStyles();
    const msg_error_currents_results = useSelector(state => state.operative.msg_error_currents_results);
    const [swalPropsError, setSwalPropsError] = useState({
        show: true,
        text: 'Colaborador no existe, no puede registrarse ninguna muestra',
        type: 'success',
        icon: 'error' 
    });
    const [swalProps, setSwalProps] = useState({
        show: true,
        text: 'Encuesta registrada con exito',
        type: 'success',
        icon: 'success',
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInitOperatives());
    }, []);
    const operativesArray = useSelector(state => state.operative.operatives);
    const currentOperativePoll = useSelector(state => state.operative.currentOperativePoll);
    const create_status = useSelector(state => state.operative.create_status);
    const patient = useSelector(state => state.operative.patient);
    const only_poll = useSelector(state => state.operative.only_poll);
    const disabled = useSelector(state => state.operative.disabled);
    const msgOk = useSelector(state => state.operative.msgOk);
    const okInputs = useSelector(state => state.operative.okInputs);
    const {
        state,
        errors,
        handlerSubmit,
        handlerclickUpdatePoll,
        handlerChange,
        handlerChangeDni,
        handlerChangeOperativePoll,
    } = useValidatorforms(pollInitState, sendPatientPoll);

    function sendPatientPoll(){
        dispatch(savePollResults(patient, state, only_poll));
        state.dni = '';
        state.encuesta_rcv = [];
        state.encuesta_pos = [];
    }  
    return ( 
        <GridContainer className="global-container">
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon className={`${ProfileClass.iconBack} content-icons`}>
                            <IconIM />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        {create_status ? (
                            <SweetAlert2 {...swalProps} 
                            />
                        ) : null}
                        <form
                            onSubmit={handlerSubmit}
                            className='form-operative'
                        >
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <SelectOperative 
                                        classes={ProfileClass}
                                        operatives={operativesArray}
                                        handlerChangeOperative={handlerChangeOperativePoll}
                                        state={state}
                                    />
                                </GridItem>
                                {msg_error_currents_results ? (
                                     <SweetAlert2 {...swalPropsError} 
                                        didClose={() => {
                                            window.location.href = '/';
                                        }}
                                     />
                                ) : null}
                                {currentOperativePoll ? (
                                    <>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <InputDni 
                                                classes={ProfileClass}
                                                handlerChange={handlerChange}
                                                handlerChangeDni={handlerChangeDni}
                                                state={state}
                                                errors={errors}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} className={`show-section-${okInputs}`}>
                                            <HealthBenefits 
                                                currentOperative={currentOperativePoll}
                                                handlerChange={handlerChange}
                                                handlerclickUpdatePoll={handlerclickUpdatePoll}
                                                state={state}
                                                msgOk={msgOk}
                                                errors={errors}
                                                onlyPoll={true}
                                                title="Encuestas"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} className={`btn-center show-section-${okInputs}`}>
                                            <Button
                                                className={`${classes.backButton} happ-background-color color-white`}
                                                type="submit"
                                                disabled={disabled}
                                            >
                                                Guardar
                                            </Button>
                                        </GridItem>                                            
                                    </>
                                ) : null}
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>                    
    );
}
 
export default Poll;