import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import validateFormInputs from './form-inputs-validators/inputsForms';
import { regiones, communesByReg } from 'happ-services/operative/operative-services';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { checkRut, formatRut } from "react-rut-formatter";
import {getCurrentOperative} from 'happ-redux/happ-actions/operative/operativeActions';

const useValidatorFrom = (initialState, fn) => {
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [submit, setSubmit] = useState(false);
    const [comunes, setComunes] = useState(null);
    const [stateReg, setStateReg] = useState(null);
    const dispatch = useDispatch();
    const patientDni = useSelector(state => state.operative.patient);
    const questionsObj = useSelector(state => state.operative.questionsObj);
    const form_inputs_results = useSelector(state => state.operative.form_inputs_results);
    const currentOperativeProfile = useSelector(state => state.operative.currentOperativeProfile);
    const currentOperativeSampling = useSelector(state => state.operative.currentOperativeSampling);
    const arrayPos = useSelector(state => state.operative.arrayPos);
    const input_patient_company = useSelector(state => state.operative.input_patient_company);
    const location = useLocation();
    const {region} = state;
    const {encuesta_rcv} = state;

    useEffect(() => {
        const updateQuestionsResults = () => {
            const data = questionsObj;
            const pos = arrayPos;
            if(data && location.pathname === '/encuestas') {
                setState({
                    ...state,
                    encuesta_rcv: data,
                    encuesta_pos: pos
                });
            } 
        }
        updateQuestionsResults()
    }, [location, questionsObj, arrayPos])

    useEffect(() => {
        const updateData = () => {
            const data =  patientDni;
            if ( data && location.pathname === '/' ) {
                console.log(data);
                setState({
                    firstName : data?.firstName, 
                    dni : data?.dni,
                    lastName : data?.lastName.split(' ')[0],
                    lastName2 : data?.lastName.split(' ')[1] !== undefined ? data?.lastName.split(' ')[1] : '',
                    email : data?.email,
                    phone : data?.phone,
                    gender : data?.gender,
                    healthForecast : data?.healthForecast,
                    birthDay : moment.utc(data?.birthDay).format('YYYY-MM-DD'),
                    street : data?.address[0]?.street,
                    commune : data?.address[0]?.commune,
                    region : data?.address[0]?.region,
                    company : data?.company,
                    operative : currentOperativeProfile,
                    branchOffice : data?.branchOfficeId
                })
            }
            return
        }
        updateData();
    }, [patientDni, location]);
    useEffect(() => {
        const updateInputsResults = () => {
            const data = form_inputs_results;
            if ( data !== null && location.pathname === '/toma-muestra' ){
                const {
                    ayunas,
                    glicemia,
                    peso,
                    altura,
                    deportista,
                    colesterol,
                    trigliceridos,
                    sistolica_bi,
                    diastolica_bi,
                    circunferencia,
                    comentarios
                } = data;
                setState({
                    ...state,
                    operative: currentOperativeSampling,
                    ayunas,
                    glicemia,
                    peso,
                    altura,
                    deportista,
                    colesterol,
                    trigliceridos,
                    sistolica_bi,
                    diastolica_bi,
                    circunferencia,
                    comentarios
                })
            }
            return;
        }
        updateInputsResults()
    }, [location, form_inputs_results]);
    useEffect(() => {
        const updateComapanyField = () => {
            const data = input_patient_company;
            if (location.pathname === '/' && data !== null){
                setState({
                    ...state,
                    company: input_patient_company.company,
                });
            }
        }
        updateComapanyField();
    }, [input_patient_company, location]);

    useEffect(() => {
        if (submit) {
            if ( !Object.keys(errors).length ){
                fn();
            }
            setSubmit(false);
        }
    }, [errors, submit, fn]);

    useEffect(() => {
        if (region) {
            const comunes = communesByReg(region);
            setComunes(comunes);
        } else {
            setComunes(null);
        }
    }, [region])

    useEffect(() => {
        setStateReg(regiones);
    }, []);
    
    
    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setState({
            ...state,
            dni: formatted
        });
    }


    const handlerChangeOperativeSampling = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        if ( e.target.value !== '' ){
            dispatch(getCurrentOperative(state, e.target.value, 'sampling'));
        }
    }

    const handlerChangeOperativePoll = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        if ( e.target.value !== '' ){
            dispatch(getCurrentOperative(state, e.target.value, 'poll'));
        }
    }

    const handlerclickUpdatePoll = (value, question) => {
        let format = value === -1 ? true: false;
        let newEncuesta_rcv = encuesta_rcv.map(obj => {
            if ( obj.item === question ) {
                return {...obj, value:format}
            } 
            return obj;
        });
        setState({
            ...state,
            encuesta_rcv: newEncuesta_rcv
        });
    }

    const handlerChangeOperativeProfile = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        if ( e.target.value !== '' ){
            dispatch(getCurrentOperative(state, e.target.value, 'profile'));
        }
    }

    const handlerChangeReg = e => {
        const comunes = communesByReg(e.target.value);
        setComunes(comunes);
        setState({
            ...state,
            region: e.target.value, 
            commune: ''
        });     
    }

    const handlerDateChange = date => {
        const dateFormat = moment(date).format('YYYY-MM-DD');
        setState({
            ...state,
            birthDay: dateFormat
        });
    }
    
    const handlerSubmit = e => {
        e.preventDefault();
        const arrayErrors = validateFormInputs(state);
        setErrors(arrayErrors);
        setSubmit(true);
    }
    
    return {
        state, 
        comunes,
        stateReg,
        errors,
        handlerChange, 
        handlerSubmit,
        handlerDateChange,
        handlerChangeReg,
        handlerChangeOperativeSampling,
        handlerChangeOperativePoll,
        handlerclickUpdatePoll,
        handlerChangeDni,
        handlerChangeOperativeProfile,
    }
}
export default useValidatorFrom;