import React from 'react';

export const IconIM = (props) => (
    <svg
        {...props}
        viewport="0 0 50 50"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        width="50px"
        height="50px"
        fill="#fff"
    >
        <g>
            <path
                className="st0" 
                d="M21.7,6.8c1.7-1.1,4-1.1,5.7,0l2,1.2c2.9,1.7,6.1,2.1,9.4,2.5c1.1,0.1,2.1,0.3,3.2,0.4c0,0,0,1.8,0,2.7
                l-0.2,7.2c-0.2,6.1-2.9,11.9-7.6,16.2c-2.2,2-4.5,3.8-7.1,5.3c-1.6,0.9-3.5,1-5.1,0.2c-3-1.4-5.7-3.3-8.2-5.5
                c-4.7-4.2-7.4-10-7.6-16.2L6,13.7C6,12.8,6,11,6,11c0.8-0.1,1.7-0.2,2.6-0.3c3.9-0.4,7.8-0.7,11.1-2.7L21.7,6.8z M22,22v-7h4v7h7v4
                h-7v7h-4v-7h-7v-4H22z" 
            />
        </g>
    </svg>
);

export const IconUser = (props) => (
    <svg
        {...props}
        viewport="0 0 50 50"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        width="50px"
        height="50px"
        fill="#fff"
    >
        <g>
            <path
                className="st0" 
                d="M24,42c9.9,0,18-8.1,18-18S33.9,6,24,6S6,14.1,6,24S14.1,42,24,42z M24,44c11,0,20-9,20-20S35,4,24,4S4,13,4,24
                S13,44,24,44z" 
            />
            <path className="st1" d="M12,35.6c0-1,0.8-1.9,1.8-2c7.7-0.9,12.7-0.8,20.4,0c1,0.1,1.8,1,1.8,2c0,0.6-0.2,1.1-0.7,1.5
                c-9.1,7.9-14.3,7.8-22.7,0C12.2,36.7,12,36.2,12,35.6z"/>
            <path className="st0" d="M34.1,34.6c-7.6-0.8-12.6-0.9-20.2,0c-0.5,0.1-0.9,0.5-0.9,1c0,0.3,0.1,0.6,0.3,0.7c4.2,3.9,7.3,5.6,10.4,5.6
                c3.1,0,6.4-1.7,10.9-5.7c0.2-0.2,0.3-0.5,0.3-0.7C35,35.1,34.6,34.7,34.1,34.6z M13.7,32.6c7.8-0.9,12.9-0.8,20.6,0
                c1.5,0.2,2.7,1.4,2.7,3c0,0.9-0.4,1.7-1,2.2c-4.6,4-8.4,6.2-12.3,6.1c-3.9,0-7.5-2.2-11.8-6.2c-0.6-0.6-1-1.4-1-2.2
                C11,34.1,12.1,32.8,13.7,32.6z"/>
            <path className="st1" d="M32,20c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S32,15.6,32,20z"/>
            <path className="st0" d="M24,26c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6S20.7,26,24,26z M24,28c4.4,0,8-3.6,8-8s-3.6-8-8-8s-8,3.6-8,8
                S19.6,28,24,28z"/>
        </g>
    </svg>
);

export const IconCalendar = (props) => (
    <svg
    {...props}
    viewport="0 0 50 50"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="50px"
    height="50px"
    fill="#fff"
>
    <g>
        <path className="st0" d="M15,23c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2H15z M15,25v2h2v-2H15z"/>
        <path className="st0" d="M21,25c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2V25z M23,25h2v2h-2V25z"/>
        <path className="st0" d="M31,23c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2H31z M31,25v2h2v-2H31z"/>
        <path className="st0" d="M13,33c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2V33z M15,35v-2h2v2H15z"/>
        <path className="st0" d="M23,31c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-2c0-1.1-0.9-2-2-2H23z M25,33h-2v2h2V33z"/>
        <path className="st0" d="M29,33c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2v2c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2V33z M31,35v-2h2v2H31z"/>
        <path className="st0" d="M16,6c-0.6,0-1,0.4-1,1v3h-4c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h26c2.2,0,4-1.8,4-4V14c0-2.2-1.8-4-4-4h-2v3
            c0,0.6-0.4,1-1,1s-1-0.4-1-1V7c0-0.6-0.4-1-1-1s-1,0.4-1,1v3H19v3c0,0.6-0.4,1-1,1s-1-0.4-1-1V7C17,6.4,16.6,6,16,6z M9,38V21h30v17
            c0,1.1-0.9,2-2,2H11C9.9,40,9,39.1,9,38z"/>
    </g>
</svg>
);

export const IconFicha = (props) => (
    <svg
    {...props}
    viewport="0 0 50 50"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="50px"
    height="50px"
    fill="#fff"
>
    <g>
        <path className="st0" d="M20,4c-1.7,0-3,1.3-3,3h-4c-1.7,0-3,1.3-3,3v31c0,1.7,1.3,3,3,3h22c1.7,0,3-1.3,3-3V10c0-1.7-1.3-3-3-3h-4
	c0-1.7-1.3-3-3-3H20z M19,7c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1v2c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V7z M19,15v3h-3v2h3v3h2v-3h3
	v-2h-3v-3H19z M17,26c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1s-0.4-1-1-1H17z M16,32c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1
	s-0.4,1-1,1H17C16.4,33,16,32.6,16,32z M17,36c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1s-0.4-1-1-1H17z"/>
    </g>
</svg>
);

export const IconExamenes = (props) => (
    <svg
    {...props}
    viewport="0 0 50 50"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="50px"
    height="50px"
    fill="#fff"
>
    <g>
        <path className="st0" d="M17,7c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3h3c1.7,0,3,1.3,3,3v15h-2V10c0-0.6-0.4-1-1-1h-3c0,1.7-1.3,3-3,3h-8
	c-1.7,0-3-1.3-3-3h-3c-0.6,0-1,0.4-1,1v28c0,0.6,0.4,1,1,1h12v2H14c-1.7,0-3-1.3-3-3V10c0-1.7,1.3-3,3-3H17z M20,6c-0.6,0-1,0.4-1,1
	v2c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1H20z M23,16v3h-3v2h3v3h2v-3h3v-2h-3v-3H23z M28,27c-0.6,0-1,0.4-1,1v6.8
	c0,2.3,1.2,4.5,3.1,5.8l3.3,2.2c0.3,0.2,0.8,0.2,1.1,0l3.3-2.2c1.9-1.3,3.1-3.5,3.1-5.8V28c0-0.6-0.4-1-1-1H28z M37.7,32.7l-4,4
	c-0.4,0.4-1,0.4-1.4,0l-2-2l1.4-1.4l1.3,1.3l3.3-3.3L37.7,32.7z"/>
    </g>
</svg>
);

export const IconHeart = (props) => (
    <svg
    {...props}
    viewport="0 0 33 33"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="33px"
    height="33px"
    fill="#fff"
>
    <g>
        <path className="st0" d="M6,18.7C6,12.6,10,7,15.6,7c3.8,0,6.7,2.5,8.4,6.1C25.8,9.5,28.6,7,32.4,7C38,7,42,12.6,42,18.7
        c0,1.1-0.1,2.1-0.4,3.2c-1.9-2.4-4.9-3.9-8.1-3.9C27.7,18,23,22.7,23,28.5c0,4.2,2.4,7.7,5.9,9.4C26.1,39.9,24,41,24,41
        S6,32.3,6,18.7z"/>
        <path className="st0" d="M36,29c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-0.1,0-0.2,0-0.3l-2.4-2.4l1.4-1.4l2.3,2.3C33.5,27,33.8,27,34,27
            C35.1,27,36,27.9,36,29z"/>
        <path className="st1IconHeart" d="M42,28.5c0,4.7-3.8,8.5-8.5,8.5S25,33.2,25,28.5s3.8-8.5,8.5-8.5S42,23.8,42,28.5z M40,28.5
            c0,3.6-2.9,6.5-6.5,6.5S27,32.1,27,28.5s2.9-6.5,6.5-6.5S40,24.9,40,28.5z"/>
    </g>
</svg>
);

export const IconIndicators = (props) => (
    <svg
    {...props}
    viewport="0 0 33 33"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="33px"
    height="33px"
    fill="#fff"
>
    <g>
        <path className="st0" d="M8,40V7H6v34c0,0.6,0.4,1,1,1h35v-2H8z"/>
        <path className="st0" d="M15.3,26.1l-7.5,9.5l-1.6-1.2l7.5-9.5L15.3,26.1z"/>
        <path className="st0" d="M27.7,28.9l-12-4l0.6-1.9l12,4L27.7,28.9z"/>
        <path className="st0" d="M38.8,16.6l-10,12l-1.5-1.3l10-12L38.8,16.6z"/>
        <path className="st1" d="M19,24c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S19,22.3,19,24z"/>
        <path className="st0" d="M16,25c0.6,0,1-0.4,1-1s-0.4-1-1-1c-0.6,0-1,0.4-1,1S15.4,25,16,25z M16,27c1.7,0,3-1.3,3-3s-1.3-3-3-3
            s-3,1.3-3,3S14.3,27,16,27z"/>
        <path className="st1" d="M31,28c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S31,26.3,31,28z"/>
        <path className="st0" d="M28,29c0.6,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S27.4,29,28,29z M28,31c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3
            S26.3,31,28,31z"/>
        <path className="st1" d="M41,16c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S41,14.3,41,16z"/>
        <path className="st0" d="M38,17c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1s-1,0.4-1,1C37,16.6,37.4,17,38,17z M38,19c1.7,0,3-1.3,3-3s-1.3-3-3-3
            s-3,1.3-3,3S36.3,19,38,19z"/>
    </g>
</svg>
);

export const IconSettings = (props) => (
    <svg
    {...props}
    viewport="0 0 33 33"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="33px"
    height="33px"
    fill="#495057"
>
    <g>
        <path className="st0" d="M20,6h8v0.7c0.6,6.9,2.7,8.1,8.9,5.2l0.7-0.4l4,6.9l-0.4,0.2c-5.9,4.1-6,6.5-0.1,10.6l0.5,0.3l0,0l-4,6.9
        L36.8,36c-6.4-2.9-8.3-1.5-8.8,6l0-0.3V42h-8v-1.2c-0.7-6.6-2.8-7.6-9.2-4.6l-0.4,0.3l-4-6.9l1-0.6c5.2-3.8,5.2-6.1-0.1-9.9
        l-0.9-0.5l4-6.9l0.2,0.1c6.5,3.1,8.7,2.2,9.4-4.5V6z M29,24c0,2.8-2.2,5-5,5s-5-2.2-5-5s2.2-5,5-5S29,21.2,29,24z M32,24
        c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S32,19.6,32,24z M34,24c0,5.5-4.5,10-10,10c-5.5,0-10-4.5-10-10s4.5-10,10-10
        C29.5,14,34,18.5,34,24z"/>
    </g>
</svg>
);

export const IconMegaphone = (props) => (
    <svg
        {...props}
        viewport="0 0 8 8"
        focusable="false"
        aria-hidden="true"
        fill="#FFF"
        width="8px"
        height="8px"
    >
        <path d="M12,31v7c0,1.1,0.9,2,2,2s2-0.9,2-2v-7H12z"/>
        <path d="M7,26v-6c0-1.7,1.3-3,3-3h6v12h-4.5H10C8.3,29,7,27.7,7,26z"/>
        <path d="M34,23v-1c0-1.7-1.3-3-3-3v7C32.7,26,34,24.7,34,23z"/>
        <path d="M18,30V15l9.5-6C28.1,8.6,29,9,29,9.8v25.4c0,0.8-0.9,1.3-1.5,0.8L18,30z"/>
        <path d="M40.8,17.4c0.3,0.5,0.2,1.1-0.3,1.4l-3,2c-0.5,0.3-1.1,0.2-1.4-0.3c-0.3-0.5-0.2-1.1,0.3-1.4l3-2
            C39.9,16.9,40.5,17,40.8,17.4z M36,24c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1s-0.4,1-1,1h-4C36.4,25,36,24.6,36,24z M36.2,27.4
            c0.3-0.5,0.9-0.6,1.4-0.3l3,2c0.5,0.3,0.6,0.9,0.3,1.4c-0.3,0.5-0.9,0.6-1.4,0.3l-3-2C36,28.5,35.9,27.9,36.2,27.4z"/>
    </svg>
);